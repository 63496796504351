.emphasized-text {
  font-size: 62px;
  margin-top: 120px;
}

/* ON MOBILE */
@media only screen and (max-width: 600px) {
  .emphasized-text {
    font-size: 36px;
  }

  .slider-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
  }

  .slider-container>div, .slider-container>div>div {
    height: 30vh !important;
    width: 100vw !important;
  }
}

/* ONLY ON BIG */
@media only screen and (min-width: 601px) {
  .full-img {
    height: 100%;
  }
}

.home-text {
  font-size: 24px;
  line-height: 26.4px !important;
  margin-top: 30px;
}

.slider {
	margin-top:30px;
	padding-top:23px;
	padding-bottom:5px;
}

.slider-container {
  padding-top: 40px;
  margin-left: 9vw;
}

.jumbotron {
	background-color:#fff;
	text-align:center;
}

.jumbotron h1 {
	color:#24325D;
	margin-bottom:20px;
}

.jumbotron p {
	line-height:1.6em;
}

section.box {
	background-color:#f9f9f9;
	text-align:center;
	padding-top:50px;
	padding-bottom:50px;
	width: 100vw;
}

.services  {
	text-align:center;
}

.services p {
	margin-bottom:30px;
	margin-top:30px;
	color:#090909;
  font-size: 14px;
}

.services h4 {
  font-size: 18px;
}

.padding-top-60 {
  padding-top: 60px;
}
