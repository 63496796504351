
/* ON MOBILE */
@media only screen and (max-width: 600px) {
  .navbar-header {
    padding-left: 0 !important;
  }

  .navbar-brand {
    margin-top: 0 !important;
  }
}

.navbar-toggler {
  color: grey;
  border: 1px solid #ddd;
  position: absolute;
  right: 10px;
  top: 10px;
}

.navbar-header{
	padding-left: 63px;
}

.navbar-collapse.collapse {
	padding-left: 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 1px;
  font-size: 30px;
  line-height: 20px;
	margin-right: 0;
	margin-top: -12px;
}

a.navbar-brand span {
	color:#24325D;
	font-size:35px;
}

.dropbtn {
    background-color: #F8F8F8;
    color: black;
    padding: 20px;
    font-size: 14px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #24325D; color: #3E95DA
}

.dropdown:hover .dropbtn a {
  color: #3E95DA
}

.navbar {
  background-color: #F8F8F8;
  padding: 0;
}

.navbar-collapse {
	float:inherit;
	padding-right: 15px;
	padding-left: 15px;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	border-top: 0;
}

.zero-top-padding {
  padding-top: 0;
}

.nav-tabs {
  border-bottom:0;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 20px 30px !important;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 0;
  border-radius: 0 0 0 0;
   text-transform:uppercase;
}

.nav-tabs > li > a:hover {
	background-color: #24325D;
}

.nav-tabs > div.active > button {
  color: #fff;
  background-color: #24325D !important;
  border: 0;
  border-bottom-color: transparent;
}

.nav-tabs > li.active > a {
	color: #fff;
  cursor: default;
  background-color: #24325D;
  border: 0;
  border-bottom-color: transparent;
  height: 100%;
}

.nav-tabs > li.active > a:hover {
	color: #fff;
	border: 0;
	background-color: #24325D;
  cursor: pointer;
  height: 100%;
}
