/* ==== Google font ==== */
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,700,600);

/* @import url('font-awesome.css'); */
/* ==== overwrite bootstrap standard ==== */
/* @import url('overwrite.css');
@import url('animate.css'); */

body {
	font-family:  Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif";
	font-size:14px;
	font-weight:300;
	line-height:1.6em;
	color:#333;
	background-color:#fff;
}

hi,h2,h3,h4,h5,h6 {
	color:#333;
	line-height:1.1em;
}

p {
	color:#333;
}
a {
    text-decoration:none;
    transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    outline: none;
	color:#333;
}
a:hover {
  text-decoration: none;
  color:#3E95DA;
}

hr {
	margin-top:20px;
	margin-bottom:50px;
}

/* ON MOBILE */
@media only screen and (max-width: 600px) {
	.body-container {
		padding: 40px 20px 40px 20px !important;
	}
}

.body-container {
	padding: 40px 60px 40px 60px;
}

.footer-container {
	padding: 10px 60px 0 60px;
}

.team-row {
	padding-top: 50px;
	padding-bottom: 200px;
}

.breadcrumb {
	margin-top:50px;
	text-align:center;
	border-radius: 0;
	background-color: #f5f5f5;
	padding: 15px 0 12px 0;
  margin-bottom: 20px;
	margin-top: 0;
  list-style: none;
}

.breadcrumb h2 {
	color:#24325D;
	font-size:30px;
}

.mapouter {
		text-align: right;
		height: 50vh;
		width: 100%;
}

.gmap_canvas {
		overflow: hidden;
		background: none !important;
		height: 100%;
		width: 100%;
}

#gmap_canvas {
	height: 100%;
	width: 100%;
}

.icons i {
	padding:50px;
	border-radius:50%;
	color:#fff;
	background-color:#24325D;
	border-color:#ccc;
	margin-top:30px;
	margin-bottom:30px;
	box-shadow: 0 0 0 8px rgba(255,255,255,1);
	-webkit-transition: background 0.2s, color 0.2s;
	-moz-transition: background 0.2s, color 0.2s;
	transition: background 0.2s, color 0.2s;
}
.icons i:hover {
	background-color:#fff;
	color: #24325D;
}

.margin-20 {
	margin-top: 40px;
	margin-bottom: 40px;
}

/*=========================
 events
 ================= */
.events h2 {
	margin-top:60px;;
}

.events p {
	margin-top:20px;
}
.events .jessa {
	margin-bottom:60px;
}
.events .slider {
	background-color:#eee;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 0;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #333;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #fff;
  background-color: #24325D;
  border-color: #24325D;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #24325D;
  border-color: #24325D;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
/*===========================
  Portfolio
============================ */
#section-works {
	margin-top:-20px;
	background-color:#fff;
	padding-top:50px;
	padding-bottom:50px;
}

nav#filter a {
	background-color: #fff;
	color:#333;
}
nav#filter a:hover, nav#filter a.current {
	background-color: #24325D;
	color:#fff;
}

/* ------- */

.isotopeWrapper article{
    margin-bottom: 30px;
}

#portfolio img  {
	width:100%;
}

#portfolio article p {
	margin-bottom:1.45em;/*set next row on the baseline*/
}
nav#filter {
	margin-bottom:1.5em;
}

nav#filter li {
	display:inline-block;
	margin:0 0 0 5px;
}
nav#filter a {
	padding: 4px 12px;
	line-height: 20px;
	text-decoration: none;
}

.portfolio-items article img {width:100%;}

.portfolio-item {
	display: block;
	position: relative;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.portfolio-item img {
	-webkit-transition: all 300ms linear;
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-ms-transition: all 300ms linear;
	transition: all 300ms linear;
}

.portfolio-item .portfolio-desc {
    display: block;
    opacity: 0;
    position: absolute;
    width: 100%;
	left: 0;
	top: 0;
	color: rgba(220, 220, 220, 0.7);
}
.portfolio-item:hover .portfolio-desc {
	padding-top: 20%;
	height: 100%;
    transition: all 200ms ease-in-out 0s;
	opacity: 1;
}

.portfolio-item .portfolio-desc a {
	color: #000;
}
.portfolio-item .portfolio-desc a:hover {
	/*text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.7);*/
	text-decoration: none;
}
.portfolio-item .portfolio-desc .folio-info {
	top:-20px;
	padding: 30px;
	height: 0;
    opacity: 0;
    position: relative;
}
.portfolio-item:hover .folio-info{
	height: 100%;
    opacity: 0.8;
    transition: all 500ms ease-in-out 0s;
	top:-10px;
	text-align:center;
}


.portfolio-item .portfolio-desc .folio-info h5 {
	text-transform: uppercase;
	text-align:center;

}
/* --- contact-area --- */


button.btn-default {
	margin-top:10px;
	margin-bottom:30px;
	background-color:#fff;
	color:#333;
}
button.btn-default:hover {
	background-color:#24325D;
	color:#fff;
}
textarea.form-control {
	border-radius:0;
}
.recent {
	margin-bottom:50px;
}


/* --- Map --- */
.map{
	position:relative;
	margin-top:-20px;
}

.map iframe{
	width:100%;
	height:550px;
	border:none;
}

.map-grid iframe{
	width:100%;
	height:350px;
	border:none;
	margin:0 0 -5px 0;
	padding:0;
}




/*=========================
  Footer
 ================= */

footer {
  background: #24325D;
}

.inner-footer {
  padding: 10px 0;
}
.inner-footer  ul li,a  {
	 list-style:none;
}
.widgetheading {
  font-size: 24px;
  font-weight: 100;
  color: #F3F3F3;
  margin-top: 0;
  margin-bottom: 40px;

}
.f-about a h1 {
	margin-top: 0;
	margin-bottom: 40px;
	color: #fff;

}
.f-about a h1 span {
	color: #fff;

}
.f-about p {
  font-size: 14px;
  color: #8a8d94;
}
.l-posts h3 {
	margin-left:40px;
}
.l-posts li a {
  font-size: 14px;
  color: #8a8d94;
  margin: 0;
  font-weight: 300;
  border-bottom: 1px solid #2c2d2e;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: block;
}

.l-posts li a:hover  {
  color: #fff;
}

.f-contact p {
  font-size: 14px;
  color: #8a8d94;
  margin: 0;

  font-weight: 300;
  margin-bottom: 15px;
}

.f-contact i {
  color: #56595f;
  font-size: 16px;
  margin-right: 10px;
}

.f-contact a:hover p{
  color: #fff;
}
.last-div {
    background: #1f2021;
    padding: 8px 0;
    position: relative;

}

.copyright {
    font-size: 14px;
    color: #8a8d94;
    margin: 0;
    text-align:center;
    font-weight: 300;
    float: left;
}
.copyright a{
    color: #8a8d94;
	text-align:center;
}
.copyright a:hover {
    text-decoration: underline;
    color: #24325D;
}

.foot-nav {
    float: right;
	margin-right:20px;
}

.foot-nav li {
    display: inline;
	margin-right:20px;
}

.foot-nav li:after {
    content: "";
    padding-left: 7px;
    margin-right: 7px;
}

.foot-nav li:last-child:after {
	display: none;
}

.foot-nav a {
	font-size: 14px;
	color: #8a8d94;
	margin: 0;
	font-weight: 300;
}

.foot-nav a:hover {
    color: #24325D;
}

ul.social-network {
	list-style:none;
	margin:0;
	float:right;

}

ul.social-network li {
	display:inline;
	margin: 0 10px;
	color:#fff;
}
ul.social-network li a {
	color:#fff;
}
