.name-link {
  margin-top: 15px;
}

.ourstory img {
	margin-bottom:30px;
	margin-top:40px;
}

.ourstory p {
	margin-bottom:30px;
	margin-top:20px;
}

.profile {
	margin-bottom:60px;
}

.staff-row {
  margin-bottom: 40px;
}
